import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'

export const Teach = ({ slice }) => {
  return (
    <section className="Teach">
      <div className="Container">
        <div className="grid">
          <div className="copy-wrap w-5">
            <PrismicRichText
              field={slice.primary.copy_richtext?.richText}
            />
          </div>
          {slice.items.map((item,index) => (
            <div className={`card-wrap nth-${index % 4}`} key={`teachCard: ${index}`}>
              <div className="card">
                <GatsbyImage
                  image={item.image?.gatsbyImageData}
                  alt={item.image?.alt || ""}
                />
                <div className="card-copy">
                  <PrismicRichText
                    field={item.card_richtext?.richText}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyTeach on PrismicHomepageDataBodyTeach {
    id
    primary {
      copy_richtext {
        richText 
      }
    }
    items {
      image {
        gatsbyImageData
        alt 
      }
      card_richtext {
        richText
      }
    }
  }
`
