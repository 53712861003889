import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'

export const CardSlider = ({ children }) => {
  
  function NextArrow(props) {
    const { className, onClick} = props;
    return (
      <button
        className="nextArrow"
        onClick={onClick}
        tabIndex="0"
        aria-label="next"
      ><FaChevronRight/></button>
    );
  }
  function PrevArrow(props) {
    const { className, onClick} = props;
    return (
      <button
        className="prevArrow"
        onClick={onClick}
        tabIndex="0"
        aria-label="prev"
      ><FaChevronLeft/></button>
    );
  }
  const settings = {
    dots: false,

    speed: 500,
    adaptiveHeight:false,
    initialSlide: 0,
    centerMode: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    infinite: true,
    className:"Slider",
    variableWidth:true,
    accessibility: true,
    focusOnSelect: true
    
  };

  return (
    <div className="carousel">
      <Slider {...settings}>
        {children}     
      </Slider>
    </div>
  )
}
