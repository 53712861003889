import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { FaLinkedin, FaTwitter, FaFacebookF, FaInstagram} from 'react-icons/fa'
import { LanguageSwitcher } from './LanguageSwitcher'

export const TopMenu = ({ menu, activeDocMeta }) => {
  const isBrowser = () => typeof window !== "undefined"
  const [isMobile, setIsMobile] = React.useState(isBrowser() && window.innerWidth < 1024);
  const [click, setClick] = React.useState(false);
  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  React.useEffect(() => {
    window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 1024;
        if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);

  const menuLinks = (
    <ul className="menu_links">
      {menu.menu_links.map((item,index) => (
        <li key={`menu-link: ${index}`}>
          <PrismicLink href={item.link?.url}>
            {item.link_label}
          </PrismicLink>
        </li>
      ))}
      <LanguageSwitcher activeDocMeta={activeDocMeta} />
    </ul>
  )
  const mobileMenu = (
    <>
      <div className={click ? "nav-icon close" : "nav-icon"} onClick={handleClick} tabIndex="0">
        <span className="ham bar-1"/>
        <span className="ham bar-2"/>
        <span className="ham bar-3"/>
      </div>
      <div className={click ? "mobile-menu open" : "mobile-menu"}>
        { menuLinks }
      </div>
    </>
  )
  return (
    <header>
      <a className="skip_button" href="#main">   
        {menu.skip_to_content_label}
      </a>
      <div className="banner">
        <PrismicLink href={menu.linked_in?.url}>
          <p className="visually-hidden">LinkedIn</p>
          <FaLinkedin/>
        </PrismicLink>
        <PrismicLink href={menu.twitter?.url}>
          <p className="visually-hidden">Twitter</p>
          <FaTwitter/>
        </PrismicLink>
        <PrismicLink href={menu.facebook?.url}>
          <p className="visually-hidden">Facebook</p>
          <FaFacebookF/>
        </PrismicLink>
        <PrismicLink href={menu.instagram?.url}>
          <p className="visually-hidden">Instagram</p>
          <FaInstagram/>
        </PrismicLink>
      </div>
      <div className="nav-menu">
        <PrismicLink href={menu.homepage_link?.url} className="nav-logo">
          <GatsbyImage
            image={menu.logo?.gatsbyImageData}
            alt={menu.logo?.alt || ""}
          />
        </PrismicLink>
        
        {isMobile ? mobileMenu : menuLinks}
      </div> 
    </header>
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicMenu {
    _previewable
    type
    lang
    data {
      skip_to_content_label
      logo {
        gatsbyImageData(
          width: 300
          imgixParams: {q: 100}
        )
        alt 
      }
      homepage_link {
        url
      }
      linked_in {
        url
      }
      twitter {
        url
      }
      facebook {
        url
      }
      instagram {
        url
      }
      menu_links {
        link {
          url
        }
        link_label
      }
    }
  }
`
