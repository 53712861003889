import * as React from 'react'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby-link'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'

export const ContactForm = ({ slice,context }) => {
  const thank_you = context.lang.slice(0,2) == "fr" ? "/thankYou-fr/" : "/thankYou/";

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }
  const [state, setState] = React.useState({})
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/en/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }
  return (
    <section className="ContactForm">
      <div className="Container">
        <div className="flex-wrap">
          <div className="copy-wrap">
            <PrismicRichText field={slice.primary.copy_richtext?.richText}/>
            <form 
              method="POST" 
              netlify-honeypot="bot-field" 
              data-netlify="true" 
              name="contact"
              onSubmit={handleSubmit}
              action={thank_you}
            >
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                </label>
              </p>
              <input type="hidden" name="form-name" value="contact" onChange={handleChange}/>
              <label htmlFor="name">{slice.primary.name_header}</label>
              <input id="name" type="text" name="name" required onChange={handleChange}/>
              <label htmlFor="email">{slice.primary.email_header}</label>
              <input id="email" type="email" name="email" required onChange={handleChange}/>
              <label htmlFor="message">{slice.primary.message_header}</label>
              <textarea id="message" name="message" type="text" rows="8" onChange={handleChange}/>
              <input type="submit" value={slice.primary.submit_placeholder} className="btn-green" onChange={handleChange}/>
            </form>
          </div>
          <div className="image-wrap">
            <div className="image-box">
              <GatsbyImage
                image={slice.primary.image?.gatsbyImageData}
                alt=""
                role="presentation"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyContactForm on PrismicPageDataBodyContactForm {
    id
    primary {
      copy_richtext {
        richText
      }
      name_header
      email_header
      message_header
      submit_placeholder
      image {
        gatsbyImageData(width: 452)
        alt 
      }
    }
  }
`
