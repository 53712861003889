import {Banner} from './Banner'
import {HomepageHero} from './HomepageHero'
import {Newsletter} from './Newsletter'
import {Partners} from './Partners'
import {Programs} from './Programs'
import {Supporters} from './Supporters'
import {Teach} from './Teach'

import {AlternatingTextImage} from './AlternatingTextImage'
import {ContactForm} from './ContactForm'
import {Emphasis} from './Emphasis'
import {PageHero} from './PageHero'
import {Socials} from './Socials'
import {Resources} from './Resources'
import {ProgramListings} from './ProgramListings'

export const components = {
  banner: Banner,
  homepage_hero: HomepageHero,
  newsletter: Newsletter,
  partners: Partners,
  programs: Programs,
  supporters: Supporters,
  teach: Teach,

  alternating_text_image: AlternatingTextImage,
  contact_form: ContactForm,
  emphasis: Emphasis,
  page_hero: PageHero,
  socials: Socials,

  resources: Resources,
  program_listings: ProgramListings,
}
