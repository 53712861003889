import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'

export const Partners = ({ slice }) => {
  return (
    <section className="Partners">
      <div className="Container">
        <div className="flex-wrap">
          <div className="copy-wrap">
            <div>
              <PrismicRichText
                field={slice.primary.copy_richtext?.richText}
              />
            </div>
            <StaticImage
              src="../images/tree-people.png"
              alt=""
              role="presentation"
              className="copy-image"
            />
          </div>
          <div className="grid">
            {slice.items.map((item,index) => (
              <PrismicLink 
                className="card" 
                key={`partnerCard: ${index}`}
                href={item.link?.url}
              >
                <GatsbyImage
                  image={item.image?.gatsbyImageData}
                  alt={item.image?.alt || ""}
                />
              </PrismicLink>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPartners on PrismicHomepageDataBodyPartners {
    id
    primary {
      copy_richtext {
        richText
      }
    }
    items {
      image {
        gatsbyImageData(width: 184)
        alt 
      }
      link {
        url 
      }
    }
  }
  fragment PageDataBodyPartners on PrismicPageDataBodyPartners {
    id
    primary {
      copy_richtext {
        richText
      }
    }
    items {
      image {
        gatsbyImageData(width: 184)
        alt 
      }
      link {
        url 
      }
    }
  }
`
