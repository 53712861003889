import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'

export const Emphasis = ({ slice }) => {
  return (
    <section className="Emphasis">
      <div className="Container">
        <div className="center-wrap">
          <PrismicRichText field={slice.primary.copy_richtext?.richText}/>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyEmphasis on PrismicPageDataBodyEmphasis {
    id
    primary {
      copy_richtext {
        richText
      }
    }
  }
`
