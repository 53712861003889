import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { FaSearch, FaChevronRight } from 'react-icons/fa'

export const ProgramListings = ({ slice }) => {
  const tags = slice.primary.key_learnings_nav?.split(",") || [];
  const programs = slice.items;

  const [category, setCategory] = React.useState(programs);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [active, setActive] = React.useState("All");

  React.useEffect(() => { 
    setCategory(
      active == "All" ? programs :
      programs.filter(prog => prog.key_learnings.includes(active)) || null
    );
  }, [active]);
  React.useEffect(() => {
    const results = !searchTerm ? programs : programs.filter(cur =>
      cur.header.toLowerCase().includes(searchTerm.toLowerCase()) ||
      cur.desc_richtext?.text.toLowerCase().includes(searchTerm)
    );
    setCategory(results);
  }, [searchTerm]);

  const handleSearch = event => {
    setActive("All");
    setSearchTerm(event.target.value.toLowerCase());
  };
  const handleChange = event => {
    setActive( event.target.value);
  };
  const card = (item,index) => (
    <div className="card" key={`program: ${index}`}>
      <div className="image-tag" aria-hidden="true">
        <p>{item.image_tag}</p>
      </div>
      {item.link?.url ?  
        (
          <PrismicLink className="image-box" href={item.link?.url} tabIndex="-1">
            <GatsbyImage
              image={item.image?.gatsbyImageData}
              alt={item.image?.alt || ""}
              className="image"
            />
          </PrismicLink>
        ) :
        (
          <div className="image-box">
            <GatsbyImage
              image={item.image?.gatsbyImageData}
              alt={item.image?.alt || ""}
              className="image"
            />
          </div>
        )
      }
      
      <div className="card-copy">
        <h3>{item.header}</h3>
        <p className="key-learnings">{slice.primary.key_learnings_label} {item.key_learnings}</p>
        <PrismicRichText field={item.desc_richtext?.richText}/>
        <div className="btn-box">
          <PrismicLink href={item.link?.url} className="btn-green">
            {item.link_label}<FaChevronRight/>
          </PrismicLink>
        </div>
      </div>
    </div>
  ); 
  const filtered_list = (cur) => {
    var feature = cur?.slice(0,2);
    var tail = cur.length > 2 ? cur.slice(2) :  [];
    return (
      <div className="card-grid">
        {feature.map((item,index) => (
          card(item,index)
        ))}
        <div className="offerings">
            <div className="flex-wrap">
              <div className="copy-wrap">
                <PrismicRichText field={slice.primary.offerings_richtext?.richText}/>
              </div>
              <div className="image-wrap">
                <GatsbyImage
                  image={slice.primary.offerings_image?.gatsbyImageData}
                  alt={slice.primary.offerings_image?.alt || ""}
                />
              </div>
            </div>
        </div>
        {tail.map((item,index) => (
          card(item,index)
        ))}
      </div>
    )
  }
  return (
    <section className="ProgramListings">
      <div className="bg-blob"/>
      <div className="Container">
        <div className="flex-wrap nav">
          <div className="btn-nav">
            <h2 className="H3">{slice.primary.categories_header}</h2>
            <div className="categories">
              <button  
                className={active == "All" ? "btn-simple select" : "btn-simple"} 
                style={{padding:"6.5px 35px"}}
                onClick={handleChange} 
                value={"All"}
                aria-label={active == "All" ? 
                  `Active ${slice.primary.all_button_label}` :
                  slice.primary.all_button_label
                }
                tabIndex="0"
              >
                {slice.primary.all_button_label}
              </button>
              {tags.map((item,index)=> (
                <button 
                  className={active == item ? "btn-simple select" : "btn-simple"} 
                  key={`btn: ${index}`} 
                  onClick={handleChange} 
                  value={item[0] == " " ? item.slice(1) : item}
                  aria-label={active == item ? 
                    `Active ${item}` :
                    item
                  }
                >
                  {item[0] == " " ? item.slice(1) : item}
                </button>
              ))}
            </div>
          </div>
          <div className="search-input" id="search"> 
            <h2 className="H3">{slice.primary.search_header}</h2>
            <div className="input-wrap" >
              <label htmlFor="Search" className="visually-hidden"/>
              <input type="text" onChange={handleSearch} value={searchTerm} id="Search"/>
              <FaSearch/>
            </div>
          </div>
        </div>
        <div>
          <div>
            {filtered_list(category)}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyProgramListings on PrismicPageDataBodyProgramListings {
    id
    primary {
      key_learnings_nav
      key_learnings_label
      categories_header
      search_header
      all_button_label
      offerings_richtext {
        richText
      }
      offerings_image {
        gatsbyImageData
        alt 
      }
    }
    items {
      image {
        gatsbyImageData(width: 695, layout: FULL_WIDTH)
        alt 
      }
      image_tag
      header 
      key_learnings 
      desc_richtext {
        richText
        text
      }
      link {
        url
      }
      link_label
    }
  }
`
