import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'

export const AlternatingTextImage = ({ slice }) => {
  return (
    <section className="AlternatingTextImage">
      <div className="Container">
        {slice.items.map((item,index) => (
          <div className={`flex-wrap nth-${index}`} key={`alternate: ${index}`}>
            <div className="copy-wrap">
              <div className="copy">
                <PrismicRichText field={item.copy_richtext?.richText}/>
              </div>
            </div>
            <div className="image-wrap">
              <GatsbyImage
                image={item.image?.gatsbyImageData}
                alt= ""
                role="presentation"
                className="image"
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyAlternatingTextImage on PrismicPageDataBodyAlternatingTextImage {
    id
    items {
      image {
        gatsbyImageData(width: 720)
      }
      copy_richtext {
        richText
      }
    }
  }
`
