import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { CardSlider } from './../components/CardSlider'

export const Programs = ({ slice }) => {
  
  return (
    <section className="Programs">
      <div className="Container">
        <div className="header">
          <PrismicRichText field={slice.primary.header_richtext?.richText}/>
        </div>
        <div className="program-slides">
          <CardSlider>
            {slice.items.map((item,index) => (
              <div className="card" key={`program: ${index}`}>
                {item.link?.url ?  
                  (
                    <PrismicLink className="image-box" href={item.link?.url} tabIndex="-1">
                      <GatsbyImage
                        image={item.image?.gatsbyImageData}
                        alt={item.image?.alt || ""}
                        className="image"
                      />
                    </PrismicLink>
                  ) :
                  (
                    <div className="image-box">
                      <GatsbyImage
                        image={item.image?.gatsbyImageData}
                        alt={item.image?.alt || ""}
                        className="image"
                      />
                    </div>
                  )
                }
                <div className="card-copy">
                  <PrismicRichText field={item.card_richtext?.richText}/>
                  <div className="btn-box">
                    <PrismicLink href={item.link?.url} className="btn-arrow">
                      {slice.primary.view_resource_label}
                    </PrismicLink>
                  </div>
                </div>
              </div>
            ))}
          </CardSlider>
        </div>
        <div className="center-wrap">
          <PrismicLink 
            className="btn-green" 
            href={slice.primary.see_all_link?.url}
          >
            {slice.primary.see_all_label}
          </PrismicLink>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPrograms on PrismicHomepageDataBodyPrograms {
    id
    primary {
      header_richtext {
        richText
      } 
      view_resource_label

      see_all_label
      see_all_link {
        url
      }
    }
    items {
      image {
        gatsbyImageData
        alt 
      }
      image_tag
      card_richtext {
        richText
      }
      link {
        url 
      }
    }
  }
`
