import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'

export const HomepageHero = ({ slice }) => {
  return (
    <section className="HomepageHero">
     
      <div className="Container">
        
        <div className="flex-grid">
          
          <div className="copy-wrap c1">
            <PrismicRichText 
              field={slice.primary.top_richtext?.richText}
            />
          </div>
          <div className="image-wrap i1">
            <div className="side top"/>
            <GatsbyImage
              image={slice.primary.top_image?.gatsbyImageData}
              alt= ""
              role="presentation"
            />
          </div>
        
          <div className="image-wrap i2">
            <div className="side bottom"/> 
            <GatsbyImage
              image={slice.primary.bottom_image?.gatsbyImageData}
              alt= ""
              role="presentation"
            />
          </div>
          <div className="copy-wrap c2">
            <PrismicRichText 
              field={slice.primary.bottom_richtext?.richText}
            />
          </div>
           
        </div>
       
      </div>
     
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHomepageHero on PrismicHomepageDataBodyHomepageHero {
    id
    primary {
      top_richtext {
        richText
      }
      bottom_richtext {
        richText
      }
      top_image {
        gatsbyImageData(width: 600)
      }
      bottom_image {
        gatsbyImageData(width: 405)
      }
    }
  }
`
