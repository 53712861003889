import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'

export const PageHero = ({ slice }) => {
  return (
    <section className="PageHero">
      <div className="Container">
        <div className="flex-wrap">
          <div className="copy-wrap">
            <div className="copy">
              <h1>{slice.primary.title}</h1>
              <PrismicRichText field={slice.primary.hero_richtext?.richText}/>
            </div>
            <StaticImage
              src="../images/tree-people.png"
              alt=""
              role="presentation"
              width={170}
              className="copy-image"
            />
          </div>
          <div className="image-wrap">
            <div className="image-box">
              <GatsbyImage
                image={slice.primary.image?.gatsbyImageData}
                alt=""
                role="presentation"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyPageHero on PrismicPageDataBodyPageHero {
    id
    primary {
      title 
      hero_richtext {
        richText
      }
      image {
        gatsbyImageData(width: 483)
        alt 
      }
    }
  }
`
