import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'

export const Supporters = ({ slice }) => {
  return (
    <section className="Supporters">
      <div className="Container">
        <div className="flex-wrap">
          <div className="copy-wrap">
            <PrismicRichText
              field={slice.primary.copy_richtext?.richText}
            />
          </div>
          <div className="images-wrap">
            {slice.items.map((item,index) => (
              <PrismicLink
                href={item.link?.url}
                key={`supporter: ${index}`}
                className="image-box"
              >
                <GatsbyImage
                  image={item.image?.gatsbyImageData}
                  alt={item.image?.alt || ""}
                  className="image"
                />
              </PrismicLink>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodySupporters on PrismicHomepageDataBodySupporters {
    id
    primary {
      copy_richtext {
        richText
      }
    }
    items {
      image {
        gatsbyImageData(width: 385)
        alt 
      }
      link {
        url 
      }
    }
  }
  fragment PageDataBodySupporters on PrismicPageDataBodySupporters {
    id
    primary {
      copy_richtext {
        richText
      }
    }
    items {
      image {
        gatsbyImageData(width: 214)
        alt 
      }
      link {
        url 
      }
    }
  }
`
