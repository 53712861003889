import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'

export const Socials = ({ slice }) => {
  return (
    <section className="Socials">
      <div className="Container">
        <div className="center-wrap">
          <h2>{slice.primary.social_header}</h2>
          <div className="flex-row">
            {slice.items.map((item,index) => (
              <PrismicLink className="social" href={item.link?.url} key={`social: ${index}`}>
                <GatsbyImage
                  image={item.image?.gatsbyImageData}
                  alt={item.image?.alt || ""}
                  className="icon"
                />
                {item.username}
              </PrismicLink>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodySocials on PrismicPageDataBodySocials {
    id
    primary {
      social_header
    }
    items {
      image {
        gatsbyImageData(width: 28)
        alt 
      }
      link {
        url 
      }
      username
    }
  }
`
