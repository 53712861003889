import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'

export const Newsletter = ({ slice }) => {
  return (
    <section className="Newsletter">
      <div className="Container">
        <div className="flex-wrap">
          <div className="copy-wrap w-5">
            <PrismicRichText
              field={slice.primary.copy_richtext?.richText}
            />
            <form>
              <input type="email" placeholder={slice.primary.input_label}/>
              <input type="submit" className="btn-blue" value={slice.primary.button_label}/>
            </form>
          </div>
          <div className="image-wrap">
            <GatsbyImage
              image={slice.primary.image?.gatsbyImageData}
              alt={slice.primary.image?.alt || ""}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyNewsletter on PrismicHomepageDataBodyNewsletter {
    id
    primary {
      copy_richtext {
        richText
      }
      input_label
      button_label
      image {
        gatsbyImageData(width: 394)
        alt 
      }
    }
  }
`
