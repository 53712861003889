import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { CardSlider } from './../components/CardSlider'
import { FaChevronRight } from 'react-icons/fa'

export const Resources = ({ slice }) => {
  return (
    <section className="Resources">
      <div className="bg-blob"/>
      <div className="Container">
        <div className="header">
          <PrismicRichText field={slice.primary.header_richtext?.richText}/>
        </div>
        <div className="program-slides">
          <CardSlider>
            {slice.items.map((item,index) => (
              <div className="card" key={`program: ${index}`}>
                {item.link?.url ?  
                  (
                    <PrismicLink className="image-box" href={item.link?.url} tabIndex="-1">
                      <GatsbyImage
                        image={item.image?.gatsbyImageData}
                        alt={item.image?.alt || ""}
                        className="image"
                      />
                    </PrismicLink>
                  ) :
                  (
                    <div className="image-box">
                      <GatsbyImage
                        image={item.image?.gatsbyImageData}
                        alt={item.image?.alt || ""}
                        className="image"
                      />
                    </div>
                  )
                }
                <div className="card-copy">
                  <PrismicRichText field={item.card_richtext?.richText}/>
                  <div className="btn-box">
                    <PrismicLink href={item.link?.url} className="btn-green" >
                      {slice.primary.view_resource_label}<FaChevronRight/>
                    </PrismicLink>
                  </div>
                </div>
              </div>
            ))}

          </CardSlider>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyResources on PrismicPageDataBodyResources {
    id
    primary {
      header_richtext {
        richText
      }
      view_resource_label
    }
    items {
      image {
        gatsbyImageData(width: 594)
        alt 
      }
      card_richtext {
        richText
      }
      link {
        url
      }
    }
  }
`
